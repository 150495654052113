import React from 'react'
import styled from '@emotion/styled'
import GatsbyIcon from 'assets/images/social/gatsby.svg'
import ReactIcon from 'assets/images/social/logo-react.svg'
import EmotionIcon from 'assets/images/emotion.png'
import { css } from '@emotion/core'

const FooterWrapper = styled.footer`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;

  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
  font-size: ${props => props.theme.space[3]};

  /* text-shadow: 2px 2px #eeeeee; */
  text-shadow: ${props => props.theme.shadow.text.medium};
  z-index: ${props => props.theme.zIndex[1]};
  ${props => props.theme.animations.fadeIn};
`
const SIZE = 16
const iconStyles = css`
  display: inline-block;
  height: ${SIZE}px;
  width: ${SIZE}px;
  margin-left: 4px;
`

const Gatsby = styled(GatsbyIcon)`
  ${iconStyles}
`
const RIcon = styled(ReactIcon)`
  ${iconStyles}
`

const Emotion = styled.img`
  ${iconStyles}
`

const Text = styled.span`
  color: gray;
  font-size: 12px;
`
const BuildWith = styled.div`
  /* position: fixed;
  right: 12px;
  bottom: 12px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`

const GoTo = styled.a`
  position: relative;
  text-decoration: none;
  display: inline-block;
  cursor: pointer;

  &:hover span {
    /* visibility: visible; */
    /* transition: opacity 1s; */
    opacity: 1;
    transform: translateY(-5px);
  }
`
const ToolTipText = styled.span`
  /* visibility: hidden; */
  opacity: 0;
  width: 90px;
  background-color: grey;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  transition: all 500ms;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -42px;
  margin-bottom: 4px;

  &::after {
    content: ' ';
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: grey transparent transparent transparent;
  }
`

function Footer() {
  return (
    <FooterWrapper>
      <span>Version - Mark II | </span>
      <BuildWith>
        <Text>Built using</Text>
        <GoTo href="https://www.gatsbyjs.org/" target="_blank">
          <Gatsby />
          <ToolTipText>Gatsby</ToolTipText>
        </GoTo>
        <GoTo href="https://reactjs.org/" target="_blank">
          <RIcon />
          <ToolTipText>React</ToolTipText>
        </GoTo>
        <GoTo href="https://emotion.sh/" target="_blank">
          <Emotion alt="emotionJS" src={EmotionIcon} />
          <ToolTipText>Emotion</ToolTipText>
        </GoTo>
      </BuildWith>
    </FooterWrapper>
  )
}

export default Footer
