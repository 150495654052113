import LinkedIn from 'assets/images/social/linkedin.svg'
import Github from 'assets/images/social/github.svg'
import Twitter from 'assets/images/social/twitter.svg'
import Medium from 'assets/images/social/medium.svg'
import Gmail from 'assets/images/social/mail.svg'

export const ICON_SIZE = 25

export const iconsConfig = [
  {
    href: 'https://www.linkedin.com/in/anooprav7/',
    IconComponent: LinkedIn,
    colorKey: 'linkedIn'
  },
  {
    href: 'https://github.com/anooprav7',
    IconComponent: Github,
    colorKey: 'github'
  },
  {
    href: 'https://twitter.com/anooprav7',
    IconComponent: Twitter,
    colorKey: 'twitter'
  },
  {
    href: 'https://medium.com/@anooprav7',
    IconComponent: Medium,
    colorKey: 'medium'
  },
  {
    href: 'mailto:anooprav7@gmail.com',
    IconComponent: Gmail,
    colorKey: 'gmail'
  }
]
