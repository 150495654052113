import React from 'react'
import Header from 'components/Header'
import DetailsBlock from 'components/DetailsBlock'
import SeoLinks from 'components/SeoLinks'
import Layout from 'components/layout'
import Footer from 'components/Footer'
import { ThemeProvider } from 'emotion-theming'

import theme from 'theme'

export default () => (
  <ThemeProvider theme={theme}>
    <Layout>
      <SeoLinks />
      <Header />
      <DetailsBlock />
      {/* <Footer /> */}
    </Layout>
  </ThemeProvider>
)
