import React, { useState } from 'react'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core'

import SocialBar from 'components/SocialBar'
import config, {
  ICON_SIZE,
  MOBILE_CARD_WIDTH_IN_VW,
  SHOW_BLUE_BANNER_MOBILE,
  CARD_BORDER_RADIUS
} from './config'

import AvatarBlock from '../AvatarBlock'

const appearOpacity = keyframes`
  0% { 
    opacity: 0; 
    margin-top: 0;
    }
  100% { 
    opacity: 1; 
    margin-top: -5vh;
    }
`

const FullPageContainer = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  /* background: radial-gradient(#48b8e6 20%, transparent 19%),
    radial-gradient(#4cc9c8 20%, transparent 19%), transparent;
  background-size: 6px 6px;
  background-position: 0 0, 3px 3px; */
`

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background-color 2s;
  transition: box-shadow 500ms;

  animation: ${appearOpacity} 1s ease forwards;

  border-radius: ${CARD_BORDER_RADIUS};
  background-color: white;

  @media (min-width: ${props => props.theme.breakpoints.mobile}) {
    /* padding: 36px; */
    padding: ${props => props.theme.space[7]};
    box-shadow: 0 0 40px 0px rgba(0, 0, 0, 0.17);
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    box-shadow: ${SHOW_BLUE_BANNER_MOBILE
      ? '0 0 40px 0px rgba(0, 0, 0, 0.17)'
      : ''};

    width: ${MOBILE_CARD_WIDTH_IN_VW};
    padding-bottom: ${props => props.theme.space[7]};
  }
`

const ItemWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`
const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${props =>
    props.theme.space[4] + ' 0 ' + props.theme.space[7] + ' 0'};
`

const IconText = styled.div`
  margin: ${props => props.theme.space[1] + ' 0'};
  text-shadow: ${props => props.theme.shadow.text.medium};
  font-weight: ${props => props.theme.fontWeights.lighter};
  font-family: sans-serif;
`
const Title = styled(IconText)`
  font-size: ${props => props.theme.fontSizes[6]};
  text-align: center;
  font-weight: ${props => props.theme.fontWeights.light};
  margin-bottom: ${props => props.theme.space[2]};
  font-family: sans-serif;
`
const SubTitle = styled(IconText)`
  font-size: ${props => props.theme.fontSizes[3]};
`

const DetailItems = config.map((item, idx) => {
  let Icon = null

  if (item.Icon) {
    Icon = styled(item.Icon)`
      height: ${ICON_SIZE}px;
      width: ${ICON_SIZE}px;
      margin-right: ${props => props.theme.space[3]};
      color: ${item.color};
    `
  }

  return (
    <ItemWrapper key={idx}>
      {Icon && <Icon />}
      {item.title ? (
        <Title>{item.text}</Title>
      ) : (
        <SubTitle>{item.text}</SubTitle>
      )}
    </ItemWrapper>
  )
})

function DetailsBlock() {
  return (
    <FullPageContainer>
      <Wrapper>
        <AvatarBlock />
        {/* <BulbIcon /> */}
        <ItemContainer>{DetailItems}</ItemContainer>
        {/* <PageCorners /> */}
        <SocialBar />
      </Wrapper>
    </FullPageContainer>
  )
}

export default DetailsBlock
