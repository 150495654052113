/** @jsx jsx */
import { css, keyframes } from '@emotion/core'

const appearOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`
const appearOpacityAndMoveUp = keyframes`
  0% { opacity: 0; margin-top: 0;}
  100% { opacity: 1; margin-top: -5vh;}
`

const fadeIn = css`
  animation: ${appearOpacity} 1s ease forwards;
`
const fadeInAndMoveUp = css`
  animation: ${appearOpacityAndMoveUp} 500ms ease forwards;
`

export default {
  colors: {
    white: '#ffffff',
    black: '#000000',
    primary: {
      50: '#dff3fb',
      100: '#afe1f4',
      200: '#7ccdee',
      300: '#48b9e6',
      400: '#1cabe2',
      500: '#009ddf',
      600: '#0090d1',
      700: '#007dbe',
      800: '#006dab',
      900: '#004e8a'
    },
    success: {
      50: '#e5f8e9',
      100: '#c1edca',
      200: '#96e1a7',
      300: '#65d683',
      400: '#33cc66',
      500: '#00c248',
      600: '#00b23f',
      700: '#009f32',
      800: '#008e25',
      900: '#006e10'
    },
    social: {
      linkedIn: '#0e76a8',
      twitter: '#00acee',
      github: '#211F1F',
      medium: '#2f2f2f',
      gmail: '#c71610'
    }
  },
  fontSizes: [
    // combination of line height and size
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '28px',
    '32px',
    '40px',
    '48px'
  ],
  space: [
    // function with 4 as mutlitplier
    '0',
    '4px',
    '8px',
    '12px',
    '14px',
    '16px',
    '20px',
    '24px',
    '28px',
    '32px',
    '40px',
    '48px',
    '64px',
    '80px',
    '96px',
    '112px',
    '128px'
  ],
  fontWeights: {
    lighter: '200',
    light: '300',
    normal: '400',
    semibold: '500',
    bold: '600'
  },
  borders: [0, '1px solid'],
  shadow: {
    avatar: `0px 15.619px 31.2381px rgba(0, 0, 0, 0.1), 0px 7.619px 21.2381px rgba(0, 0, 0, 0.1)`,
    text: {
      small: '',
      medium: `1px 6px 4px rgba(0, 0, 0, 0.1),
        0px -5px 12px rgba(255, 255, 255, 0.3);`,
      large: ''
    },
    hover:
      '0px 1px 0px rgba(149, 157, 165, 0.1), inset 0px 2px 0px rgba(255, 255, 255, 0.03)',
    active: 'inset 0px 1px 0px rgba(4, 66, 137, 0.2)',
    focus: '0 0 0 3px rgba(3, 102, 214, 0.3)'
  },
  letterSpacing: {
    tight: '-1px',
    normal: '0px',
    semi: '0.7px',
    wide: '1px'
  },
  animations: {
    fadeIn,
    fadeInAndMoveUp
  },
  zIndex: [10, 100, 200, 300, 400, 500, 600, 700, 800, 900, 999],
  breakpoints: {
    mobile: '567px'
    // mobile: '360px'
  }
}

// https://github.com/primer/components/blob/master/src/theme.js
// https://github.com/treebohotels/leaf-ui/blob/d479ca6cc48a8d143266ad9ada8ced55806cd547/src/theme/theme.web.js
// https://github.com/grommet/grommet/blob/1fde0260e0c5563569815006db2bccf692afe0c3/src/js/themes/base.js
