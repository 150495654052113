import React, { useState } from 'react'
import styled from '@emotion/styled'

import AvatarSrc from 'assets/images/anoop_raveendran.jpg'
import ListIcon from 'assets/images/social/list-outline.svg'
import NotificationsModal from '../NotificationsModal'
import Footer from 'components/Footer'
import {
  AVATAR_SIZE,
  UPDATES_ICON_SIZE,
  MOBILE_CARD_WIDTH_IN_VW,
  AVATAR_BG_HEIGHT,
  SHOW_BLUE_BANNER_MOBILE,
  CARD_BORDER_RADIUS
} from '../DetailsBlock/config'

const Avatar = styled.img`
  border-radius: 50%;
  position: relative;
  height: ${AVATAR_SIZE}px;
  width: ${AVATAR_SIZE}px;
  box-shadow: ${props => props.theme.shadow.avatar};
  border: 1px solid #5050;
  padding: ${props => props.theme.space[1]};
  /* transition: box-shadow 350ms; */
  transition: all 350ms;
  background-color: white;
  z-index: ${props => props.theme.zIndex[1]};
  /* cursor: pointer; */

  &:hover {
    /* box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15); */
    /* box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22); */
    /* box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2); */
    /* transform: scale(1.01); */
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    margin-top: 5vh;
  }
`

const AvatarColorBlock = styled.div`
  position: absolute;
  width: 100%;
  height: ${AVATAR_BG_HEIGHT};
  top: 0;
  border-radius: 15px 15px 0 0;

  transform: skewY(-2.2deg);
  transform-origin: 100%;

  @media (min-width: ${props => props.theme.breakpoints.mobile}) {
    background-color: ${props => props.theme.colors.primary[300]};
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    background-color: ${props =>
      SHOW_BLUE_BANNER_MOBILE ? props.theme.colors.primary[300] : ''};
    width: ${MOBILE_CARD_WIDTH_IN_VW};
  }
`

const AngledBlock = styled.div`
  position: absolute;
  border-radius: ${CARD_BORDER_RADIUS} ${CARD_BORDER_RADIUS} 0 0;
  height: ${AVATAR_BG_HEIGHT};
  top: 0;
  left: 0;
  right: 0;
  @media (min-width: ${props => props.theme.breakpoints.mobile}) {
    background-color: ${props => props.theme.colors.primary[300]};
  }
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    background-color: ${props =>
      SHOW_BLUE_BANNER_MOBILE ? props.theme.colors.primary[300] : ''};
    width: ${MOBILE_CARD_WIDTH_IN_VW};
    left: auto;
    right: auto;
  }
`

const GAP = 14
const List = styled(ListIcon)`
  position: absolute;
  top: ${GAP}px;
  right: ${GAP}px;
  height: ${UPDATES_ICON_SIZE}px;
  width: ${UPDATES_ICON_SIZE}px;
  z-index: ${props => props.theme.zIndex[1]};
  color: white;
  cursor: pointer;
  transition: all 500ms;

  &:hover {
    /* color: #ffeb3b; */
    color: ${props => props.theme.colors.success[200]};
  }
`

function AvatarBlock() {
  const [open, setOpen] = useState(false)
  return (
    <>
      <Avatar src={AvatarSrc} alt="Anoop Raveendran avatar image" />
      <List
        onClick={() => {
          setOpen(true)
        }}
      />
      <AvatarColorBlock />
      <AngledBlock />
      <NotificationsModal
        open={open}
        onOutsideClick={() => {
          setOpen(false)
        }}
      />
      {!open && <Footer />}
    </>
  )
}

export default AvatarBlock
