import React from 'react'
import { Helmet } from 'react-helmet'

const title = `Anoop Raveendran`
const description = `Anoop Raveendran's profile space`
const imgUrl = 'https://anooprav7.com/anoop_raveendran.jpg'

function SeoLinks() {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Anoop Raveendran</title>
      <link rel="canonical" href="https://anooprav7.com/" />
      <link rel="canonical" href="https://www.anooprav7.com/" />
      <meta name="description" content={description}></meta>
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content="@anooprav7" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={imgUrl} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={imgUrl} />
    </Helmet>
  )
}

export default SeoLinks
