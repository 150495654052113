import React from 'react'
import logo from 'assets/images/anoop_raveendran.jpg'
// import './index.css'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import CodeIcon from 'assets/images/social/code.svg'

const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 44px;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
`
const size = 24
const Icon = styled(CodeIcon)`
  height: ${size}px;
  width: ${size}px;
`

export default () => <Header>{/* <Icon /> */}</Header>

// 104.198.14.52
// 151.101.1.195
// 151.101.65.195
