import MapPin from 'assets/images/social/location-outline.svg'
import Activity from 'assets/images/social/activity.svg'
import GitBranch from 'assets/images/social/git-branch-outline.svg'
import GitCommit from 'assets/images/social/git-commit-outline.svg'

export default [
  {
    // Icon: UserIcon,
    text: 'Anoop Raveendran',
    color: '#FFC107',
    title: true
  },
  {
    Icon: MapPin,
    text: 'Bengaluru, India',
    color: '#4CAF50'
  },
  {
    Icon: Activity,
    text: 'JavaScript, React, Node',
    color: '#FF5722'
  },
  {
    Icon: GitCommit,
    text: 'curr: Senior S.E @ Walmart',
    color: '#000'
  },
  {
    Icon: GitBranch,
    text: 'prev: Microsoft, Cleartrip ...',
    color: '#000'
  }
]

export const AVATAR_SIZE = 200
export const ICON_SIZE = 20
export const UPDATES_ICON_SIZE = 24
export const MOBILE_CARD_WIDTH_IN_VW = '88vw'
export const AVATAR_BG_HEIGHT = '172px'
export const SHOW_BLUE_BANNER_MOBILE = true
export const CARD_BORDER_RADIUS = '12px'
