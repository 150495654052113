import React from 'react'
import styled from '@emotion/styled'
/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core'

import { ICON_SIZE, iconsConfig } from './config'

const appearOpacity = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Wrapper = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-wrap: wrap;
  /* @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 60vw;
  } */
`
const transitionDuration = `500ms`

const IconContainer = styled.a`
  border-radius: 50%;
  border: 1px solid black;
  height: ${2 * ICON_SIZE}px;
  width: ${2 * ICON_SIZE}px;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.colors.white};
  transition: color ${transitionDuration} ease;
  transition: background-color ${transitionDuration} ease;
  box-shadow: 0px 15.619px 31.2381px rgba(0, 0, 0, 0.15);
  transition: box-shadow ${transitionDuration};
`

const SocialIcons = iconsConfig.map((item, idx) => {
  const SocialIcon = styled(item.IconComponent)`
    fill: currentColor;
    height: ${ICON_SIZE}px;
    width: ${ICON_SIZE}px;
  `
  const halfIndex = Math.floor((iconsConfig.length - 1) / 2)
  const animationDelay = 1000 + Math.abs(halfIndex - idx) * 200
  const AnchorBlock = styled(IconContainer)`
    background-color: ${props => props.theme.colors.social[item.colorKey]};
    border: 1px solid ${props => props.theme.colors.social[item.colorKey]};
    margin-left: ${props => (idx !== 0 ? props.theme.space[4] : '0')};
    transition: transform 500ms ease;

    opacity: 0;
    animation: ${appearOpacity} 1s ease;
    animation-delay: ${animationDelay}ms;
    animation-fill-mode: forwards;

    &:hover {
      transform: scale(1.1, 1.1);
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.25);
    }
  `
  return (
    <AnchorBlock href={item.href} target="_blank" key={idx}>
      <SocialIcon />
    </AnchorBlock>
  )
})
function SocialBar() {
  return <Wrapper>{SocialIcons}</Wrapper>
}

export default SocialBar
