import React from 'react'
import { Global, css } from '@emotion/core'
import styled from '@emotion/styled'
import normalize from 'assets/styles/normalize'

const Wrapper = styled('div')`
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
`

const Layout = ({ children }) => {
  return (
    <Wrapper>
      <Global
        styles={css`
          ${normalize}
        `}
      />
      {children}
    </Wrapper>
  )
}

export default Layout
