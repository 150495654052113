import React from 'react'
import styled from '@emotion/styled'

import Medium from 'assets/images/social/medium.svg'
import Npm from 'assets/images/social/logo-npm.svg'
import Github from 'assets/images/social/github.svg'
import Close from 'assets/images/social/close-outline.svg'
import Cleartrip from 'assets/images/social/cleartrip.svg'
// import StormbreakerOld from 'assets/images/social/stormbreaker-old.svg'
import Stormbreaker from 'assets/images/social/stormbreaker.svg'
// import Stormbreaker from 'assets/images/stormbreaker.png'

/** @jsx jsx */
import { jsx, keyframes } from '@emotion/core'

const colorChange = keyframes`
  0% { background-color: white; }
  100% { background-color: #7ccdee; }
`
// #616161

const Container = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  animation: ${colorChange} 1s ease forwards;

  display: flex;
  justify-content: center;
  align-items: center;

  z-index: ${props => props.theme.zIndex[3]};
`

const Wrapper = styled.div`
  width: 50vw;
  box-sizing: border-box;
  position: relative;
  z-index: ${props => props.theme.zIndex[4]};
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  color: black;
  background-color: white;
  padding: ${props => props.theme.space[6]} ${props => props.theme.space[11]}
    ${props => props.theme.space[6]} ${props => props.theme.space[11]};
  border-radius: 7px;
  
  ${props => props.theme.animations.fadeInAndMoveUp}
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    width: 88vw;
    padding: ${props => props.theme.space[3]} ${props => props.theme.space[6]}
            ${props => props.theme.space[3]} ${props => props.theme.space[6]};
  }
`

const config = [
  {
    Icon: Medium,
    title: `'JavaScript Event loop explained' article released`,
    subTitle: `Crossed 100k+ views and 4.2k+ claps`,
    href:
      'https://medium.com/front-end-weekly/javascript-event-loop-explained-4cd26af121d4',
    color: '#000'
  },
  {
    Icon: Stormbreaker,
    title: `stormbreaker v0.2.1 released`,
    subTitle: `ReactJS + EmotionJS + Storybook docs`,
    href: 'https://stormbreaker-v2.netlify.com/',
    color: '#000'
  },
  {
    Icon: Npm,
    title: `cli-msg v1.0.0 released`,
    subTitle: `Beautified cli messages for future projects`,
    href: 'https://github.com/anooprav7/cli-msg',
    color: '#000'
  },
  {
    Icon: Cleartrip,
    title: `@cleartrip/bento alpha released`,
    subTitle: `Tree-shakable, cjs and es6 support, jest added`,
    href: 'https://bundlephobia.com/result?p=@cleartrip/bento@0.5.0-alpha.0',
    color: '#000'
  },
  {
    Icon: Npm,
    title: `stormbreaker-icons released`,
    subTitle: 'Tree-shakable, Docz documentation, svg - based',
    href: 'https://stormbreaker-icons.netlify.com/',
    color: '#000'
  },
  {
    Icon: Medium,
    title: `'JavaScript Promises explained' article released`,
    subTitle: `Latest release`,
    href:
      'https://medium.com/@anooprav7/javascript-promises-explained-e464985274e7',
    color: '#000'
  }
]

const ModalWrapper = styled.a`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  /* margin: ${props => props.theme.space[1] + ' 0'}; */
  text-decoration: none;
  padding: 12px 24px 12px 20px;
  border-radius: 4px;
  transition: all 200ms;
  /* box-shadow: 1px 1px 1px rgba(0,0,0,0.05); */
  box-shadow: 1px 1px 1px ${props => props.theme.colors.primary[300]};
  box-shadow: 1px 1px 10px lightgrey;
  /* background-color: ${props => props.theme.colors.primary[300]}; */
  background-color: white;
  margin-bottom: 8px;
  border-left: 5px solid ${props => props.theme.colors.primary[400]};
  &:visited {
    color: inherit;
  }
  &:hover {
    /* box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1); */
    transform: scale(1.02)
  }
`
const ItemContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  margin: ${props =>
    props.theme.space[4] + ' 0 ' + props.theme.space[4] + ' 0'};
`

const IconText = styled.div`
  /* margin: ${props => props.theme.space[1] + ' 0'}; */
  /* text-shadow: ${props => props.theme.shadow.text.medium}; */
  font-weight: ${props => props.theme.fontWeights.lighter};
  color: #1a1a1a;
`
const Title = styled(IconText)`
  font-size: ${props => props.theme.fontSizes[1]};
  text-align: left;
  font-weight: ${props => props.theme.fontWeights.normal};
  margin-bottom: 4px;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes[1]};
  }
`

const SubTitle = styled(IconText)`
  font-size: ${props => props.theme.fontSizes[0]};
  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes[0]};
  }
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

const DetailItems = config.map(item => {
  const Icon = styled(item.Icon)`
    display: inline-block;
    height: ${24}px;
    width: ${24}px;
    margin-right: ${props => props.theme.space[3]};
    color: ${item.color};
  `

  return (
    <ModalWrapper href={item.href} target="_blank">
      <Icon />
      <TextWrapper>
        <Title>{item.title}</Title>
        <SubTitle>{item.subTitle}</SubTitle>
      </TextWrapper>
    </ModalWrapper>
  )
})

const CloseButton = styled(Close)`
  position: absolute;
  top: 14px;
  right: 14px;
  height: ${props => props.theme.space[9]};
  width: ${props => props.theme.space[9]};
  cursor: pointer;
  color: black;
  transition: all 500ms;

  &:hover {
    color: ${props => props.theme.colors.primary[400]};
  }
`
const ModalTitle = styled.h2`
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;*/
  margin: 0; 
  /* background-color: ${props => props.theme.colors.primary[500]}; */
  /* padding: 20px 0 12px 48px; */
  font-weight: 300;
  border-radius: 7px 7px 0 0;
  /* color: white; */
  font-size: ${props => props.theme.fontSizes[7]};
  margin-top: ${props => props.theme.space[3]};
  margin-bottom: ${props => props.theme.space[8]};
  /* color: ${props => props.theme.colors.primary[400]}; */
  color: #1a1a1a;

  @media (max-width: ${props => props.theme.breakpoints.mobile}) {
    font-size: ${props => props.theme.fontSizes[4]};
  }
`

function NotificationsModal(props) {
  const { open, onOutsideClick } = props
  if (!open) return null
  return (
    <Container onClick={onOutsideClick}>
      <Wrapper
        onClick={e => {
          e.stopPropagation()
        }}
      >
        <ModalTitle>Latest updates &#129304;</ModalTitle>
        <ItemContainer>{DetailItems}</ItemContainer>
        <CloseButton onClick={onOutsideClick} />
      </Wrapper>
    </Container>
  )
}

export default NotificationsModal
